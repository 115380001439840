import { Col, DatePicker, Flex, Row, Space, Table } from 'antd';
import React from 'react';
import UsersService from '../../../API/UsersService';
import { useFetching } from "../../../hooks/useFetching";
const { RangePicker } = DatePicker;


const LeaderboardPage = () => {
    const [data, setData] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [error, setError] = React.useState('');

    const fetchData = useFetching(async (date_from, date_to) => {
        const response = await UsersService.getLeaderboard(date_from, date_to);
        setData(response.data)
    }, setIsLoading, setError)

    const onChange = (date, dateString) => {
        fetchData(`${dateString[0]}T00:00:00`, `${dateString[1]}T23:59:59`)
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (<span>{record.user_first_name} {record.user_last_name}</span>)
        },
        {
            title: 'Total',
            dataIndex: 'total_annotated',
            key: 'species',
        },
    ]

    return (
        <>
            <Row>
                <Col span={24}>
                    <h1>Leaderboard</h1>
                    <Flex justify="space-between" style={{ paddingBottom: '10px' }}>
                        <RangePicker onChange={onChange} />
                    </Flex>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Table
                        rowKey={(record) => record._id}
                        dataSource={data}
                        columns={columns}
                        loading={isLoading}
                    />
                </Col>
            </Row>
        </>
    )
}

export default LeaderboardPage;