import { BugOutlined, CloseCircleOutlined, RetweetOutlined, SlidersOutlined, StarOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import cl from "./ImageGallery.module.css";


function AnnotationLabels({ annotation }) {
    if (!annotation) return null;

    const props = {}
    if (annotation?.label) {
        if (annotation.label == 'delete') {
            props['color'] = 'red'
            props['icon'] = <CloseCircleOutlined />
        }
        if (annotation.label == 'injured') {
            props['color'] = 'green'
            props['icon'] = <BugOutlined />
        }
        if (annotation.label == 'relabel') {
            props['color'] = 'blue'
            props['icon'] = <RetweetOutlined />
        }
    }

    return (
        <div className={cl.annotationTags}>
            {annotation?.label && <Tag {...props}>{annotation.label}</Tag>}
            {annotation?.score && <Tag icon={<StarOutlined />} color="green">score: {annotation.score}</Tag>}
            {annotation?.granular && <Tag icon={<SlidersOutlined />} color="purple">{annotation.granular}</Tag>}
        </div>
    )
}

export default AnnotationLabels;