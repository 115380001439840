import axios from "axios";

const baseIstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
    timeout: 8000
});
baseIstance.interceptors.request.use(
    (config) => {
        config.headers.Authorization = `Bearer ${localStorage.getItem("refresh_token")}`

        return config
    }
)


const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
    timeout: 8000
});

instance.interceptors.request.use(
    (config) => {
        config.headers.Authorization = `Bearer ${localStorage.getItem("access_token")}`

        return config
    }
)

instance.interceptors.response.use(
    (config) => {
        return config;
    },
    async (error) => {
        console.log("INTERCEPTOR ERROR", error);
        const originalRequest = { ...error.config };
        originalRequest._isRetry = true;

        if (
            // проверим, что ошибка именно из-за невалидного accessToken
            error.response.status === 401 &&
            // проверим, что запрос не повторный
            error.config &&
            !error.config._isRetry
        ) {
            try {
                const resp = await baseIstance.post("/auth/refresh");
                localStorage.setItem("access_token", resp.data.access_token);


                return instance.request(originalRequest);
            } catch (error) {
                localStorage.removeItem("access_token");
                localStorage.removeItem("refresh_token");

                console.log("AUTH ERROR");
            }
        }

        throw error;
    }
);

export { baseIstance, instance };
