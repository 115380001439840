import { App as AntApp, Breadcrumb, Layout } from 'antd';
import 'antd/dist/reset.css';
import { Link } from "react-router-dom";
import '../../styles/App.css';
const { Sider, Content, Header } = Layout;


export default function Root({ children }) {
    return (
        <>
            <AntApp>
                <Layout style={{ minHeight: '100vh' }}>
                    <Content
                        style={{
                            backgroundColor: 'rgb(240, 242, 245)',
                            padding: '0 24px'
                        }}
                    >
                        {children}
                    </Content>
                </Layout>
            </AntApp>
        </>
    );
}