import qs from "qs";

export function getQueryFromPath(path) {
    const query = qs.parse(path.split(/\?/)[1]);

    return Object.entries(query).reduce((query, [key, value]) => {
        if (value) {
            query[key] = String(value);
        }

        return query;
    }, {});
}
