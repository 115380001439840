import React from "react";
import { Breadcrumb as AntBreadcrumbs } from "antd";
import { Link, useLocation, useMatches } from 'react-router-dom';

const Breadcrumbs = () => {
    const matches = useMatches();
    const location = useLocation();

    const items = matches
        .filter((match) => Boolean(match.handle?.breadcrumbName))
        .map((match) => {
            if (location.pathname === match.pathname) {
                return { title: match.handle.breadcrumbName }
            }

            return { title: <Link to={match.pathname}>{match.handle.breadcrumbName}</Link>}
        });

    return (
        <AntBreadcrumbs items={items} style={{ marginBottom: 24 }}/>
    );
}

export default Breadcrumbs;
