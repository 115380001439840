import { instance } from "./common.js";


export default class PlantSpeciesService {

    static async getAll(limit, offset, search, show_annotated, sort_by, sort_direction) {
        if (sort_direction !== undefined) {
            sort_direction = sort_direction === 'ascend' ? 1 : -1
        }

        const response = await instance.get('/plant_species', {
            params: {
                'limit': limit,
                'offset': offset,
                'search': search,
                'sort_by': sort_by,
                'sort_direction': sort_direction,
                'show_annotated': show_annotated
            }
        })

        return response
    }
}