import React from 'react';
import { App as AntApp, Row, Col, Form, Input, Select, Button } from 'antd';
import { UserAddOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';
import UsersService from '../../../API/UsersService';

const CreateUserPage = () => {
    const [form] = Form.useForm();
    const { message } = AntApp.useApp();

    const handleSubmit = async (values) => {
        try {
            const response = await UsersService.createUser(values);

            if (response.data?.email) {
                form.resetFields();
                message.success(`User ${response.data.email} successfully created!`);

                return;
            }

            message.error('Error creating a user.');
        } catch (err) {
            message.error('Error creating a user.');
        }
    }

    return (
        <>
            <Row>
                <Col span={24}>
                    <h1>Create user</h1>
                </Col>
            </Row>
            <Row justify='center'>
                <Col md={{ span: 12 }} sm={{ span: 24 }}>
                    <Form
                        form={form}
                        name='create_user_form'
                        layout='vertical'
                        autoComplete='off'
                        onFinish={handleSubmit}
                    >
                        <Form.Item
                            name="first_name"
                            label="First name"
                            rules={[
                                {
                                    type: 'string',
                                    required: true,
                                    message: 'Please input first name!',
                                },
                            ]}
                        >
                            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="First name" />
                        </Form.Item>
                        <Form.Item
                            name="last_name"
                            label="Last name"
                            rules={[
                                {
                                    type: 'string',
                                    required: true,
                                    message: 'Please input last name!',
                                },
                            ]}
                        >
                            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Last name" />
                        </Form.Item>
                        <Form.Item
                            name="email"
                            label="Email"
                            rules={[
                                {
                                    type: 'email',
                                    required: true,
                                    message: 'Please input email!',
                                },
                            ]}
                        >
                            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            label="Password"
                            rules={[
                                {
                                    type: 'string',
                                    required: true,
                                    message: 'Please input password!',
                                },
                                {
                                    pattern: /^\S*$/,
                                    message: 'White space is not allowed',
                                },
                                {
                                    min: 4,
                                    message: 'Password must be at least 4 characters long',
                                },
                                {
                                    max: 14,
                                    message: 'Password can not exceed 14 characters',
                                }
                            ]}
                        >
                            <Input prefix={<LockOutlined className="site-form-item-icon" />} placeholder="Password" />
                        </Form.Item>
                        <Form.Item
                            name="role"
                            label="Role"
                            rules={[
                                {
                                    required: true,
                                    message: 'PLease select role!'
                                }
                            ]}
                        >
                            <Select
                                placeholder="Role"
                                options={[
                                    { label: 'Viewer', value: 'viewer' },
                                    { label: 'Annotator', value: 'annotator' },
                                    { label: 'Admin', value: 'admin' }
                                ]}
                            />
                        </Form.Item>
                        <Form.Item style={{ marginBottom: 0 }}>
                            <Button type="primary" htmlType="submit">
                                <UserAddOutlined />
                                Create user
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </>
    );
};

export default CreateUserPage;
