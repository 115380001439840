import { instance } from "./common.js";


export default class UsersService {

    static async getCurrentUser() {
        return await instance.get('/users/me');
    }

    static async getLeaderboard(date_from, date_to) {
        const response = await instance.get('/users/leaderboard', {
            params: {
                'date_from': date_from,
                'date_to': date_to,
            }
        })

        return response
    }

    static async getUsers(limit=50) {
        return await instance.get('/users',
            {
                params: {
                    limit: limit
                }
            }
        )
    }

    static async createUser({ first_name, last_name, email, password, role }) {
        return await instance.post('/users', {
            email,
            password,
            first_name,
            last_name,
            role,
        });
    }

    static async updateUser({ first_name, last_name, email, role, password }, options = {}) {
        const response = await instance.patch('/users',
            {
                first_name,
                last_name,
                email,
                role,
                password: password || undefined,
            },
            {
                params: { update_id: options.update_id }
            },
        );

        if (response.data.access_token && response.data.refresh_token && options.setToken) {
            options.setToken({
                access_token: response.data.access_token.access_token,
                refresh_token: response.data.refresh_token.refresh_token,
            });
        }

        return response;
    }
}