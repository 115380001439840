import { App as AntApp, Button, Progress, Space, Flex } from 'antd';
import {
    ArrowRightOutlined,
    SaveOutlined,
    EyeOutlined
} from '@ant-design/icons';
import React, { useState } from 'react';
import { useParams, Link } from "react-router-dom";
import MemoImageViewer from '../../components/MemoImageViewer/MemoImageViewer';
import { useAnnotateImages } from '../../hooks/useAnnotateImages';
import cl from "./annotate.module.css";
import Root from './root';


const AnnotatePage = () => {
    const { messageApi } = AntApp.useApp();
    const { species } = useParams()
    const [isSaving, setIsSaving] = useState(false)
    const [images, percentOfAnnotated, changeImageLabel, changeImageScore, changeImageGranular, isLoading, saveAnnotation, loadMore, setImageAsLoaded, progressStats] = useAnnotateImages()

    const onSaveAndNext = async () => {
        if (isSaving) return
        if (images.filter(img => img.is_annotated == false).length > 0) {
            alert('Please annotate all images before saving!')
            return
        }
        setIsSaving(true)
        await saveAnnotation()
        setIsSaving(false)
        loadMore()
    }

    const capitalize = (s) => {
        return s.charAt(0).toUpperCase() + s.slice(1);
    }

    const handleOnChangeImage = (action, img, value) => {
        switch (action) {
            case 'label':
                changeImageLabel(img.image_id, value)
                break;
            case 'score':
                changeImageScore(img.image_id, value)
                break;
            case 'granular':
                changeImageGranular(img.image_id, value)
                break;
            default:
                console.log('no action')
                break;
        }
    }
    return (
        <Root>
            <div className={cl.spaceAlignContainer}>
                <div className={cl.spaceAlignLeftBlock}>
                    <div >
                        <Flex align='center' gap={10}>
                            <h2>
                                {capitalize(species.split('_').join(' '))} ({progressStats.total} / {progressStats.completed})
                            </h2>
                            <Link target="_blank" to={`/plant-species/${species}/images/all`}><EyeOutlined /> view mode</Link>
                        </Flex>

                        <Progress percent={percentOfAnnotated} />
                    </div>
                </div>
                <div className={cl.spaceAlignRightBlock}>
                    <Space align="end">
                        <Button disabled={isLoading || isSaving} onClick={() => onSaveAndNext()} style={{ backgroundColor: "green" }} type="primary"><SaveOutlined />Save & Next <ArrowRightOutlined /></Button>
                    </Space>
                </div>
            </div>

            <MemoImageViewer
                images={images}
                isLoading={isLoading}
                onChange={handleOnChangeImage}
                onLoadImage={(img) => { setImageAsLoaded(img.image_id) }}
            />
        </Root >
    )
}
export default AnnotatePage