import { instance } from "./common.js";


export default class AnnotationService {

    static async getImages(limit, offset, species) {
        const response = await instance.get(`/annotate/${species}/images`, {
            params: {
                'limit': limit,
                'offset': offset,
            }
        })

        return response
    }

    static async saveAnnotations(annotations) {
        const response = await instance.post(`/annotate`, {
            'annotations': annotations
        })

        return response
    }

}