import { createContext, useContext, useEffect, useMemo, useState } from "react";
const AuthContext = createContext();


const AuthProvider = ({ children }) => {
    const [token, setToken_] = useState({
        access_token: localStorage.getItem('access_token') || '',
        refresh_token: localStorage.getItem('refresh_token') || '',
    });
    const [user] = useState(() => {
        if (token.access_token && token.access_token != 'null') {
            const tokenInfo = atob(token.access_token.split('.')[1]);
            if (tokenInfo) {
                return JSON.parse(tokenInfo)['subject'];
            }
        }

        return null;
    });

    const setToken = (newToken) => {
        setToken_(newToken);
    };

    useEffect(() => {
        if (token) {
            localStorage.setItem('access_token', token.access_token);
            localStorage.setItem('refresh_token', token.refresh_token);
        } else {
            localStorage.removeItem('access_token')
            localStorage.removeItem('refresh_token')
        }
    }, [token]);

    const contextValue = useMemo(
        () => ({
            token,
            setToken,
        }),
        [token]
    );

    return (
        <AuthContext.Provider value={{ ...contextValue, user }}>{children}</AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};

export default AuthProvider;