import { instance, baseIstance } from "./common.js";

export default class AuthService {
    static async login(email, password) {
        return await instance.post("/auth/login", { email, password })
    }

    static async refreshToken() {
        return await baseIstance.post("/auth/refresh");
    }
}