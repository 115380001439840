import { App as AntApp, Col, Row, Table, Tag, Button, Flex } from 'antd';
import { UserAddOutlined, EditOutlined } from '@ant-design/icons';
import { zonedTimeToUtc } from 'date-fns-tz';
import formatDistance from 'date-fns/formatDistance';
import parseJSON from 'date-fns/parseJSON';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import UsersService from '../../../API/UsersService';
import { useFetching } from "../../../hooks/useFetching";
import { useAuth } from '../../../providers/AuthProvider';
import EditUserDrawer from '../../../components/EditUserDrawer/EditUserDrawer';

export default function UsersPage() {
    const [data, setData] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [error, setError] = React.useState('');
    const [drawerRecord, setDrawerRecord] = React.useState(null);
    const { user } = useAuth();
    const { message } = AntApp.useApp();
    const navigate = useNavigate();

    const fetchData = useFetching(async () => {
        const response = await UsersService.getUsers();
        setData(response.data)
    }, setIsLoading, setError)

    useEffect(() => {
        fetchData()
    }, [])

    const RoleTag = (role) => {
        switch (role) {
            case 'admin':
                return <Tag color="gold">Admin</Tag>
            case 'annotator':
                return <Tag color="blue">Annotator</Tag>
            case 'viewer':
                return <Tag color="cyan">Viewer</Tag>
            default:
                return <Tag color="default">Unknown</Tag>
        }
    }

    const getDiffUpdatedAt = (updated_at) => {
        return formatDistance(
            zonedTimeToUtc(parseJSON(updated_at)),
            zonedTimeToUtc(new Date()), { addSuffix: true }
        )
    }

    const handleEditUser = async (formValues) => {
        try {
            const { data } = await UsersService.updateUser(formValues, { update_id: drawerRecord.id });

            if (!data.user) {
                throw new Error();
            }

            await fetchData();

            message.success('Successfully updated user.');
        } catch (_e) {
            message.error('Error updating user.');
        }

        setDrawerRecord(null);
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (<span>{record.first_name} {record.last_name}</span>)
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
            render: (text, record) => RoleTag(record.role)
        },
        {
            title: 'Last activity',
            dataIndex: 'last_activity_at',
            key: 'last_activity_at',
            render: (text, record) => getDiffUpdatedAt(record.last_activity_at),
        },
        {
            title: 'Created',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (text, record) => zonedTimeToUtc(record.created_at).toLocaleString(),
        },
        {
            title: 'Updated',
            dataIndex: 'modified_at',
            key: 'modified_at',
            render: (text, record) => zonedTimeToUtc(record.modified_at).toLocaleString(),
        },
    ]

    if (user.role === 'admin') {
        columns.push(
            {
                title: 'Actions',
                key: 'actions',
                render: (text, record) => (
                    (record.role !== 'admin' || user.is_superuser) && (
                        <Button onClick={() => setDrawerRecord(record)}>
                            <EditOutlined />
                            Edit
                        </Button>
                    )
                )
            }
        )
    }

    return (
        <>
            <Row justify='space-between'>
                <Col>
                    <h1>Users</h1>
                </Col>
                {user.role === 'admin' && (
                    <Flex align='center'>
                        <Button
                            style={{ marginBottom: 14 }}
                            onClick={() => navigate('/dashboard/users/create-user')}
                        >
                            <UserAddOutlined />
                            Create user
                        </Button>
                    </Flex>
                )}
            </Row>
            <Row>
                <Col span={24}>
                    <Table
                        rowKey={(record) => record.id}
                        dataSource={data}
                        columns={columns}
                        loading={isLoading}
                        scroll={{ x: 'auto' }}
                    />
                </Col>
            </Row>
            <EditUserDrawer
                open={!!drawerRecord}
                initialValues={drawerRecord}
                withRoleSelect
                handleSubmit={handleEditUser}
                handleClose={() => setDrawerRecord(null)}
            />
        </>
    )
}