import { App as AntApp, Layout } from 'antd';
import 'antd/dist/reset.css';
import { useAuth } from '../../providers/AuthProvider';
import '../../styles/App.css';
const { Sider, Content, Header } = Layout;



export default function Root({ children }) {
    const { user } = useAuth();

    return (
        <>
            <AntApp>
                <Layout style={{ minHeight: '100vh' }}>
                    <Content
                        style={{
                            backgroundColor: 'rgb(240, 242, 245)',
                            padding: '10px 24px'
                        }}
                    >
                        {children}
                    </Content>
                </Layout>
            </AntApp>
        </>
    );
}