import { Tooltip } from 'antd';
import React, { useState } from 'react';
import cl from "./ImageItem.module.css";

function ChoiseCustomLabel({ onChange, value }) {
    const items = [
        {
            label: '🌼',
            value: 'flower',
            description: 'flower'
        },
        {
            label: '🍓',
            value: 'fruit',
            description: 'fruit'
        },
        {
            label: '🤍',
            value: 'variegated',
            description: 'variegated'
        }
    ]

    const handleChange = (val) => {
        onChange(val);
    }

    return (
        <>
            <div className={cl.selectCustomLabelWrapper}>
                {items.map((item, index) => {

                    return (
                        <Tooltip key={index} placement="topLeft" title={item.description}>
                            <span
                                key={index}
                                className={`${cl.selectCustomLabelItem} ${item.value == value ? cl.selectCustomLabelItemActive : ''}`}
                                onClick={() => { handleChange(item.value); }}
                            >
                                {item.label}
                            </span>
                        </Tooltip>

                    )
                })}
            </div>
        </>
    )
}
export default ChoiseCustomLabel;