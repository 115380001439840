import { instance } from "./common.js";

export default class AIExpertHelpService {

    static async getAllUserRequests({
        limit,
        offset,
        sort_by,
        sort_direction,
        state,
        date_from,
        date_to,
    }) {
        if (sort_direction !== undefined) {
            sort_direction = sort_direction === 'ascend' ? 1 : -1;
        }

        return await instance.get('/ai_expert_help/user_requests', {
            params: {
                limit,
                offset,
                sort_by,
                sort_direction,
                state,
                date_from,
                date_to,
            }
        });
    }
}
