import { FileAddOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import React, { useState } from 'react';

import cl from "./ImageItem.module.css";
function CustomLabel({ onChange }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [value, setValue] = useState('')

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        onChange(value);
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleChange = (e) => {
        setValue(e.target.value);
    }

    const setPredefinedLabel = (label) => {
        setValue(label);
    }

    return (
        <>
            <Modal title="Add custom label" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <input type="text" placeholder='Label' onChange={handleChange} value={value} />
                <ul className={cl.availableTagList}>
                    <li onClick={() => setPredefinedLabel('flower')}>flower</li>
                    <li onClick={() => setPredefinedLabel('fruit')}>fruit</li>
                    <li onClick={() => setPredefinedLabel('variegated')}>variegated</li>
                </ul>
            </Modal>

            <Button onClick={showModal} type="default"><FileAddOutlined />Custom label</Button>
        </>

    )
}
export default CustomLabel;