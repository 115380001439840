import { HomeOutlined, ZoomOutOutlined, ZoomInOutlined, RobotFilled } from '@ant-design/icons';
import { App as AntApp, Breadcrumb, Flex, Layout, Switch, Slider, Col, Row, Space } from 'antd';
import React, { useState } from 'react';
import { useParams, useSearchParams } from "react-router-dom";
import FilterBar from '../../components/FilterBar/FilterBar';
import ImageGallery from '../../components/ImageGallery/ImageGallery';
import { useAuth } from '../../providers/AuthProvider';
import cl from "./images.module.css";

const { Sider, Content, Header } = Layout;


const ImagesPage = () => {
    const { user } = useAuth();
    const { species } = useParams()
    let [searchParams] = useSearchParams();
    const [imgGridSize, setImgGridSize] = useState(parseInt(localStorage.getItem('imgGridSize') || 6));

    const [editMode, setEditMode] = useState(false)

    const capitalize = (s) => {
        return s.charAt(0).toUpperCase() + s.slice(1);
    }
    const changeGridSize = (size) => {
        localStorage.setItem('imgGridSize', size)
        setImgGridSize(size);
    }

    return (
        <>
            <AntApp>
                <Layout style={{ minHeight: '100vh' }}>
                    <Content
                        style={{
                            backgroundColor: 'rgb(240, 242, 245)',
                            padding: '10px 24px'
                        }}
                    >
                        <div className={cl.imagesContainer}>
                            <Breadcrumb
                                items={[
                                    {
                                        href: '/plant-species',
                                        title: <HomeOutlined />,
                                    },
                                    {
                                        title: capitalize(species.split('_').join(' ')),
                                    },
                                ]}
                            />
                            <Flex className={cl.toolBarPannel}>
                                <FilterBar species={species} />
                            </Flex>
                            <Flex justify='flex-end' gap={20}>
                                <Flex gap={4} align='center' justify='space-between'>
                                    <ZoomInOutlined />
                                    <Slider
                                        min={3}
                                        max={8}
                                        style={{ width: 90 }}
                                        onChange={changeGridSize}
                                        value={imgGridSize}
                                    />
                                    <ZoomOutOutlined />
                                </Flex>
                                <div className={cl.editModeBlock}>
                                    Edit mode: <Switch value={editMode} onChange={(val) => { setEditMode(val) }} />
                                </div>
                            </Flex>
                            <ImageGallery
                                key={JSON.stringify(Object.fromEntries([...searchParams]))}
                                editMode={editMode}
                                gridSize={imgGridSize}
                            />
                        </div>
                    </Content>
                </Layout>
            </AntApp >
        </>
    )
}
export default ImagesPage