import { App as AntApp, Layout } from 'antd';

import React, { useEffect } from 'react';
import { Link, RouterProvider, createBrowserRouter } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute.jsx";
import ErrorPage from './error-page';
import LoginPage from './pages/LoginPage';
import LogoutPage from './pages/LogoutPage.jsx';
import AnnotatePage from './routes/annotate/index';
import PlantSpecies from './routes/plant-species';
import ImagesPage from './routes/plant-species/images';
import AuthProvider from './providers/AuthProvider.js';
import UsersPage from './routes/dashboard/user/index.js';
import CreateUserPage from './routes/dashboard/user/CreateUserPage.jsx';
import LeaderboardPage from './routes/dashboard/user/leaderboard';
import RootDashboard from './routes/dashboard/root.js';
import DashboardPage from './routes/dashboard/index.js';
import ImageRetrievalPage from './routes/img-retrieval/index.jsx';
import UserFeedbacksPage from './routes/dashboard/user-feedbacks/index.jsx';
import AIExpertHelpPage from './routes/dashboard/ai-expert-help/index.jsx';
import ProfilePage from './routes/dashboard/profile/index.jsx';
import PlantsPage from './routes/dashboard/plants/index.jsx';
import 'antd/dist/reset.css';
import './styles/App.css';


const App = () => {
    const router = createBrowserRouter([
        {
            path: '/login',
            element: <LoginPage />,
            errorElement: <ErrorPage />,
        },
        {
            path: '/logout',
            element: <LogoutPage />,
        },
        {
            path: "/plant-species",
            element: <PrivateRoute />,
            errorElement: <ErrorPage />,
            children: [
                {
                    path: "",
                    element: <PlantSpecies />,
                    errorElement: <ErrorPage />,
                    handle: {
                        crumb: () => <Link to="/plant-species">species</Link>,
                    }
                },
                {
                    path: "/plant-species/:species/images/:filter",
                    element: <ImagesPage />,
                },
                {
                    path: "/plant-species/:species/annotate",
                    element: <AnnotatePage />,
                },
            ]
        },
        {
            path: "/dashboard",
            element: <RootDashboard />,
            errorElement: <ErrorPage />,
            handle: { breadcrumbName: 'Dashboard' },
            children: [
                {
                    path: "",
                    element: <DashboardPage />,
                    errorElement: <ErrorPage />,
                },
                {
                    path: '/dashboard/profile',
                    element: <ProfilePage />,
                    errorElement: <ErrorPage />,
                    handle: { breadcrumbName: 'Profile' },
                },
                {
                    path: "/dashboard/users",
                    errorElement: <ErrorPage />,
                    handle: { breadcrumbName: 'Users' },
                    children: [
                        {
                            path: "",
                            element: <UsersPage />,
                            errorElement: <ErrorPage />,
                        },
                        {
                            path: "/dashboard/users/create-user",
                            element: <CreateUserPage />,
                            errorElement: <ErrorPage />,
                            handle: { breadcrumbName: 'Create user' },
                        },
                        {
                            path: "/dashboard/users/leaderboard",
                            element: <LeaderboardPage />,
                            errorElement: <ErrorPage />,
                            handle: { breadcrumbName: 'Leaderboard' },
                        },
                    ]
                },
                {
                    path: "/dashboard/user_feedbacks",
                    element: <UserFeedbacksPage />,
                    errorElement: <ErrorPage />,
                    handle: { breadcrumbName: 'User feedbacks' },
                },
                {
                    path: "/dashboard/ai_expert_help",
                    element: <AIExpertHelpPage />,
                    errorElement: <ErrorPage />,
                    handle: { breadcrumbName: 'AI expert help' },
                },
                {
                    path: "/dashboard/plants",
                    element: <PlantsPage />,
                    errorElement: <ErrorPage />,
                    handle: { breadcrumbName: 'Plants' },
                },
            ]
        },
        {
            path: "/image-retrieval",
            element: <ImageRetrievalPage />,
        },
        // {
        //     path: "/sim/:species",
        //     element: <SimImagesPage />,
        // },
    ]);

    return (
        <AntApp>
            <AuthProvider>
                <RouterProvider router={router} />
            </AuthProvider>
        </AntApp>
    );
}

export default App