import { instance } from "./common.js";


export default class UserFeedbackService {

    static async getAll(limit, offset, search, show_annotated, sort_by, sort_direction) {
        if (sort_direction !== undefined) {
            sort_direction = sort_direction === 'ascend' ? 1 : -1
        }

        const response = await instance.get('/user_feedbacks', {
            params: {
                'limit': limit,
                'offset': offset,
                'sort_by': sort_by,
                'sort_direction': sort_direction
            }
        })

        return response
    }

    static async getAllGroupedByUrl(params) {
        return await instance.get('/user_feedbacks/grouped_by_url', { params });
    }

    static async changeImageStatus(img_url, status) {
        return await instance.put('/user_feedbacks/change_status', {
            img_url,
            status,
        });
    }
}