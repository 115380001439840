import { EditOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import { Button } from "antd";
import React, { useState } from 'react';
import { Fade } from 'transitions-kit';
import EditIMageModal from './EditImageModal';
import AnnotationLabels from './AnnotationLabels';
import cl from "./ImageGallery.module.css";
import { useAuth } from '../../providers/AuthProvider';


function GalleryImageItem({ img, editMode = false, onSuccessEdit = () => { } }) {
    const { user } = useAuth();
    const [isLoaded, setIsLoaded] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    console.log(user)

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    if (!img) {
        return
    }

    const getOriginalLink = (url) => {
        if (!url) {
            return
        }

        return url.replace(/(\.\w+)$/, '_orig$1');
    }

    return (
        <>
            <Fade in={isLoaded}>
                <div className={cl.imageItem}>
                    {editMode &&
                        <div className={cl.imagePlaceholderContainer} onClick={() => { showModal() }}>
                            <div className={cl.imagePlaceholder}></div>
                            <div className={cl.imagePlaceholderActions}>
                                <EditOutlined style={{ fontSize: '28px', color: '#52c41a' }} />
                            </div>
                        </div>
                    }

                    {editMode &&
                        <EditIMageModal
                            open={isModalOpen}
                            onCancel={handleCancel}
                            image={{ ...img }}
                            onSuccessSave={onSuccessEdit}
                        />
                    }
                    <AnnotationLabels annotation={img?.annotation} />
                    {user?.role === 'viewer' &&
                        <div className={cl.imagePlaceholderContainer}>
                            <div className={cl.imagePlaceholder}></div>
                            <div className={cl.imagePlaceholderActions}>
                                <Button href={getOriginalLink(img?.url)}  type='link' style={{ fontSize: "16px" }} icon={<CloudDownloadOutlined />}>Download</Button>
                            </div>
                        </div>
                    }
                    <img
                        src={img?.url}
                        onLoad={() => setIsLoaded(true)}
                    />
                </div>
            </Fade>
        </>

    )
}

export default GalleryImageItem;