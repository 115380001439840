import { instance } from "./common.js";


export default class ImagesService {

    static async getAllImages({ limit, offset, species, label, score, granular }) {

        const response = await instance.get('/images', {
            params: {
                'limit': limit,
                'offset': offset,
                'species': species,
                'label': label,
                'score': score,
                'granular': granular
            }
        })

        return response
    }

    static async getInfoAboutImageStats(species) {
        const response = await instance.get(`/images/${species}/info_about_image_stats`)

        return response
    }
}