import {
    AppstoreOutlined,
    BarsOutlined,
    UserOutlined,
    BarChartOutlined,
    InteractionOutlined,
    GlobalOutlined,
    ProfileOutlined,
    LogoutOutlined,
    TableOutlined,
} from '@ant-design/icons';
import { App as AntApp, Avatar, Col, Layout, Menu, Row, Space, Dropdown } from 'antd';
import { Link, Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../../providers/AuthProvider";
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';


import 'antd/dist/reset.css';
import React, { useState } from 'react';
const { Sider, Content, Header } = Layout;

const UserAvatar = ({ user }) => {
    return (
        <Dropdown
            arrow
            trigger={['click']}
            menu={{
                items: [
                    {
                        key: 'profile',
                        label: (
                            <Link
                                to='/dashboard/profile'
                                style={{ display: 'flex', gap: 12, justifyContent: 'space-between' }}
                            >
                                Profile
                                <ProfileOutlined />
                            </Link>
                        ),
                    },
                    {
                        type: 'divider',
                    },
                    {
                        key: 'logout',
                        label: (
                            <Link
                                to='/logout'
                                style={{ display: 'flex', gap: 12, justifyContent: 'space-between' }}
                            >
                                Logout
                                <LogoutOutlined />
                            </Link>
                        ),
                    },
                ],
            }}
        >
            <Avatar
                shape="square"
                style={{ cursor: 'pointer' }}
            >
                {user.username[0].toUpperCase()}
            </Avatar>
        </Dropdown>
    );
};

export default function RootDashboard({ children }) {
    const { token } = useAuth();
    const location = useLocation();
    const [collapsed, setCollapsed] = useState(false);
    const [user] = useState(() => {
        if (token.access_token && token.access_token != 'null') {
            const tokenInfo = atob(token.access_token.split('.')[1]);
            if (tokenInfo) {
                return JSON.parse(tokenInfo)['subject'];
            }
        }

        return null;
    });

    if (!(token.access_token && token.access_token != 'null')) {
        return <Navigate to="/login" state={{ from: location }} replace={true} />;
    }

    return (
        <>
            <AntApp>
                <Layout style={{ minHeight: '100vh' }}>
                    <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
                        <Menu
                            theme="dark"
                            mode="inline"
                            defaultSelectedKeys={['1']}
                            items={[
                                {
                                    key: 'dashboard',
                                    icon: <AppstoreOutlined />,
                                    label: <Link to='/dashboard'>Home</Link>,
                                },
                                {
                                    key: 'users',
                                    icon: <UserOutlined />,
                                    label: <Link to='/dashboard/users'>Users</Link>,
                                },
                                {
                                    key: 'leaderboard',
                                    icon: <BarChartOutlined />,
                                    label: <Link to='/dashboard/users/leaderboard'>Leaderboard</Link>,
                                },
                                {
                                    key: 'user_feedbacks',
                                    icon: <InteractionOutlined />,
                                    label: <Link to='/dashboard/user_feedbacks'>User feedbaks</Link>,
                                },
                                {
                                    key: 'ai_expert_help',
                                    icon: <GlobalOutlined />,
                                    label: <Link to='/dashboard/ai_expert_help'>AI expert help</Link>,
                                },
                                {
                                    key: 'plants',
                                    icon: <TableOutlined />,
                                    label: <Link to='/dashboard/plants'>Plants</Link>,
                                },
                            ]}
                        />
                    </Sider>
                    <Layout className="site-layout">
                        <Header style={{ paddingInline: 24, background: 'rgb(255, 255, 255)' }}>
                            <Row align='middle'>
                                <Col flex='auto' />
                                <Col>
                                    <Space style={{ position: 'relative' }}>
                                        {user && <UserAvatar user={user} />}
                                    </Space>
                                </Col>
                            </Row>
                        </Header>
                        <Content
                            style={{
                                backgroundColor: 'rgb(240, 242, 245)',
                                padding: '20px 24px'
                            }}
                        >
                            <div
                                style={{
                                    padding: 24,
                                    minHeight: 360,
                                    background: 'rgb(255, 255, 255)',
                                    borderRadius: '8px',
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <Breadcrumbs />
                                <Outlet />
                            </div>
                        </Content>
                    </Layout>
                </Layout>
            </AntApp>
        </>
    );
}