import { Empty, Image, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import ImageItem from "./ImageItem";
import cl from "./ImageGallery.module.css";


function MemoImageViewer({ images, isLoading, onChange, onLoadImage }) {
    const [loadingAllImages, setLoadingAllImages] = useState(true);
    const [visible, setVisible] = React.useState(false);
    const [scaleStep, setScaleStep] = React.useState(0.8);
    const [currentImage, setCurrentImage] = React.useState(null);

    const onPreview = (img) => {
        setCurrentImage({ 'url': img.url })
        setVisible(true)
    }

    useEffect(() => {
        if (images.map(image => { return image._is_loading }).includes(false)) {
            setLoadingAllImages(false)
        } else {
            setLoadingAllImages(true)
        }
    }, [images]);

    if (images.length == 0 && !isLoading) {
        return (
            <div className={cl.galleryContainer} id="gallery"><Empty /></div>
        )
    }

    return (
        <>
            <Image
                width={224}
                style={{ display: 'none' }}
                src={currentImage?.url}
                preview={{
                    visible,
                    scaleStep,
                    onVisibleChange: (value) => {
                        setVisible(value);
                    },
                }}
            />
            <div className={cl.galleryContainer} id="gallery">
                <Spin tip="Loading..." spinning={loadingAllImages} size="large">
                    <div className={cl.galleryGrid}>
                        {images.map(image =>
                            <ImageItem
                                onLoaded={(img) => { onLoadImage(img) }}
                                onPreview={onPreview}
                                onChangeLabel={ (img, label) => onChange('label', img, label) }
                                onChangeScore={(img, score) => onChange('score', img, score)}
                                onChangeGranular={(img, granular) => onChange('granular', img, granular)}
                                key={image.image_id}
                                img={image}
                            />
                        )}
                    </div>
                </Spin>
            </div>
        </>
    )

}

export default MemoImageViewer