import { AimOutlined, EyeOutlined, LoadingOutlined, TeamOutlined } from '@ant-design/icons';
import { Badge, Button, Col, Empty, Flex, Image, Input, Layout, Row, Segmented, Space, Statistic, Typography } from 'antd';
import React, { useState } from 'react';
import { instance } from "../../API/common.js";
import cl from "./ImageRetrieval.module.css";
const { Content } = Layout;
const { Text, Link } = Typography;


const ImageItem = ({ image }) => {
    return (
        <div className={cl.imageItem}>
            <Flex gap="middle" align="start">
                <Image src={image.url} height={224} />
                <div>
                    <Typography.Title copyable level={5} style={{ marginBottom: '20px' }}>{image.species}</Typography.Title>
                    <Flex gap={40} align='center' justify='space-around'>
                        <Statistic title="Distance metric" value={Number.parseFloat(image.score).toFixed(4)} prefix={<AimOutlined />} />
                        <Statistic title="Views" value={image.views} prefix={<EyeOutlined />} />
                        <Statistic title="Aesthetic score" value={image.aesthetic_score} prefix={<TeamOutlined />} />
                    </Flex>
                </div>
            </Flex>
        </div>
    )
}

const ImagesViewer = ({ images }) => {
    if (!images.length) {
        return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
    }
    return (
        <div className={cl.galleryContainer}>
            <div className={cl.galleryList}>
                {images.map((image, index) => (
                    image.verified ?
                        <Badge.Ribbon key={index} text="verified" color="green">
                            <ImageItem key={index} image={image} />
                        </Badge.Ribbon>
                        : <ImageItem key={index} image={image} />
                ))}
            </div>
        </div>
    );
}


const ImageRetrieval = () => {
    const [imageUrl, setImageUrl] = useState('https://placehold.co/224x224');
    const [classifaicationResults, setClassificationResults] = useState([]);
    const [image, setImage] = useState(null);
    const [results, setResults] = useState([]); // [{url: '', score: 0}]
    const [previewImage, setPreviewImage] = useState('');
    const [loading, setLoading] = useState(false);


    const handleImageUpload = (event) => {
        setImage(event.target.files[0]);
        setPreviewImage(URL.createObjectURL(event.target.files[0]));
    };

    // const handleSubmit = async (event) => {
    //     event.preventDefault();

    //     const formData = new FormData();
    //     formData.append('file', image);
    //     setResults([]);
    //     setLoading(true);
    //     try {
    //         const response = await instance.post(
    //             'search',
    //             formData,
    //             {
    //                 headers: {
    //                     'Content-Type': 'multipart/form-data',
    //                 },
    //             }
    //         );
    //         console.log('data', response);

    //         // setResults(response.data);
    //     } catch (error) {
    //         console.error(error);
    //         setResults([]);
    //     } finally {
    //         setLoading(false);
    //     }
    // };
    // const handleSearch = async (imgUrl) => {
    //     // if (!imgUrl) {
    //     //     return;
    //     // }
    //     setImageUrl(imgUrl);
    // }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const url = event.target.url.value

        if (!url) {
            return;
        }
        setImageUrl(url);
        setResults([]);
        setClassificationResults([]);
        setLoading(true);
        try {
            const response = await instance.post(
                'image_retrieval/search_by_url?limit=5',
                { 'image_url': url },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )
            setResults(response.data.retrieval);
            setClassificationResults(response.data.classification);
        } catch (error) {
            console.error(error);
            setResults([]);
            setClassificationResults([]);
        } finally {
            setLoading(false);
        }
    }

    return (
        <div>
            <Row gutter={[16, 16]}>
                <Col span={6} >
                    <Content
                        style={{
                            backgroundColor: '#ffffff',
                            padding: '10px 24px',
                            borderRadius: '8px',
                        }}>
                        <Flex gap="middle" vertical align="center">

                            <Segmented
                                defaultValue="url"
                                style={{ marginBottom: 8 }}
                                // onChange={(value) => setAlignValue(value as Align)}
                                options={['url', 'file']}
                            />
                            <Image src={imageUrl} width={224} style={{ 'borderRadius': '4px' }} />

                            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                                <Space.Compact style={{ width: '100%' }}>
                                    <Input name='url' />
                                    <Button type="primary" htmlType="submit">Submit</Button>
                                </Space.Compact>
                            </form>
                            {classifaicationResults.length > 0 &&
                                <div className={cl.classificationDistribution}>
                                    <table style={{ 'width': '100%' }}>
                                        {classifaicationResults.map((result, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td><b>{result.species}</b></td>
                                                    <td>{Number.parseFloat(result.confidence).toFixed(3)}</td>
                                                </tr>
                                            )
                                        })}
                                    </table>
                                </div>
                            }
                        </Flex>
                    </Content>
                </Col>
                <Col span={18}>
                    <Content
                        style={{
                            backgroundColor: '#ffffff',
                            padding: '10px 24px',
                            borderRadius: '8px',
                        }}>
                        {loading && <div>Loading <LoadingOutlined /></div>}
                        <ImagesViewer images={results} />
                    </Content>
                </Col>
            </Row>
        </div>
    );
}
export default ImageRetrieval