import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


function LogoutPage() {
    const navigate = useNavigate();

    useEffect(() => {
        localStorage.removeItem('access_token')
        localStorage.removeItem('refresh_token')

        navigate('/login', { replace: true });
    }, []);
}

export default LogoutPage;