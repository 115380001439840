import { App as AntApp, Spin } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useSearchParams } from "react-router-dom";
import ImagesService from "../../API/ImagesService";
import GalleryImageItem from './GalleryImageItem';
import cl from "./ImageGallery.module.css";

const pageSize = 36;

const useFetchImages = () => {
    const { message } = AntApp.useApp();
    const { species } = useParams()
    let [searchParams, setSearchParams] = useSearchParams();

    const [images, setImages] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [count, setCount] = useState(0)
    const [fetchError, setError] = useState('');
    const [offset, setOffset] = useState(0)

    const fetching = useCallback(async () => {
        try {
            if (isLoading) return;

            setIsLoading(true)
            setError('')
            const allowedParams = ['label', 'score', 'granular']
            const params = {
                'limit': pageSize,
                'offset': offset,
                'species': species,
            }
            const search = Object.fromEntries(searchParams)

            for (const key in search) {
                if (allowedParams.includes(key)) {
                    params[key] = search[key]
                }
            }

            const response = await ImagesService.getAllImages({ ...params });
            setImages(prevData => [...prevData, ...response.data.results])
            setCount(response.data.count)
        } catch (e) {
            setError(e.message);
            message.error(e.message);
        } finally {
            setIsLoading(false)
        }
    }, [offset, searchParams])

    useEffect(() => {
        fetching()
    }, [offset]);

    useEffect(() => {
        const galleryElement = document.getElementById('gallery');

        const handleScroll = (e) => {
            if (offset >= count && count > 0) {
                console.log('no more images', offset, count)
                return; // Don't load more images if offset is greater than or equal to total
            }

            console.log('scrolling', Math.ceil(e.target.scrollTop) + e.target.clientHeight, e.target.scrollHeight, offset, count)

            if (Math.ceil(e.target.scrollTop) + e.target.clientHeight >= e.target.scrollHeight) {
                setOffset(prevOffset => prevOffset + pageSize)
                console.log('load more', offset)
            }
        }

        if (galleryElement) {
            galleryElement.addEventListener('scroll', handleScroll);
            return () => galleryElement.removeEventListener('scroll', handleScroll);
        }
    }, [fetching]);

    return [images, setImages, count, isLoading]
}

function ImageGallery({ editMode = false, gridSize = 6}) {
    const { message } = AntApp.useApp();
    const [images, setImages, count, isLoading] = useFetchImages()

    const handleSuccessEdit = (img) => {
        message.success('Success save!');
        const newImages = images.filter(image => image.image_id !== img.image_id)

        setImages(newImages)
    }

    const styleGrid = {
        gridTemplateColumns: `repeat(${gridSize}, 1fr)`
    }

    return (
        <>
            <div className={cl.galleryContainer} id="gallery">
                <Spin spinning={isLoading} size="large">
                    <div style={styleGrid} className={cl.galleryGrid}>
                        {images.map(image => <GalleryImageItem onSuccessEdit={handleSuccessEdit} editMode={editMode} key={image.image_id} img={image} />)}
                    </div>
                </Spin>
            </div>
        </>
    )
}

export default ImageGallery