import React from 'react';
import { useSearchParams } from "react-router-dom";
import dayjs from 'dayjs';
import {
    Row,
    Button,
    Select,
    DatePicker,
    Space,
    Input,
    Radio
} from 'antd';
import cl from "./ai-expert-help.module.css";

const { RangePicker } = DatePicker;

const ControlPanel = () => {
    let [searchParams, setSearchParams] = useSearchParams();

    const patchSearchParams = (newParams = {}) => {
        setSearchParams((searchParams) => {
            const prevParams = Object.fromEntries([...searchParams]);

            for (const key in newParams) {
                if (newParams[key] === undefined || newParams[key] === null) {
                    delete newParams[key];
                    delete prevParams[key];
                }
            }

            return { ...prevParams, ...newParams };
        });
    };

    return (
        <Row style={{ gap: 24 }}>
            <Button
                onClick={() => patchSearchParams({
                    offset: 0,
                    state: undefined,
                    date_from: undefined,
                    date_to: undefined,
                })}
            >
                Clear Filters
            </Button>
            <Select
                placeholder="State"
                style={{ width: 160 }}
                value={searchParams.get('state') || undefined}
                onChange={(v) => patchSearchParams({ state: v, offset: 0 })}
                options={[
                    { value: 'new', label: 'New' },
                    { value: 'done', label: 'Done' },
                    { value: 'error', label: 'Error' },
                    { value: 'draft', label: 'Draft' },
                    { value: 'enqueued', label: 'Enqueued' },
                    { value: 'processing', label: 'Processing' },
                    { value: 'ready', label: 'Ready' },
                    { value: 'deleted', label: 'Deleted' },
                ]}
            />
            <RangePicker
                value={[
                    searchParams.get('date_from') ? dayjs(searchParams.get('date_from')) : null,
                    searchParams.get('date_to') ? dayjs(searchParams.get('date_to')) : null
                ]}
                onChange={(_date, dateString) => {
                    if (dateString[0] && dateString[1]) {
                        patchSearchParams({
                            date_from: `${dateString[0]}T00:00:00`,
                            date_to: `${dateString[1]}T23:59:59`,
                            offset: 0
                        });

                        return;
                    }

                    patchSearchParams({ date_from: undefined, date_to: undefined, offset: 0 });
                }}
            />
            <Space.Compact>
                <Input
                    disabled
                    value="Sorted by"
                    style={{
                        width: 90,
                        minWidth: 90,
                        color: 'currentcolor',
                        cursor: 'unset'
                    }}
                />
                <Select
                    style={{ width: 160 }}
                    value={searchParams.get('sort_by') || 'created_at'}
                    onChange={(v) => patchSearchParams({ sort_by: v })}
                    options={[
                        { value: 'created_at', label: 'Created at' },
                        { value: 'updated_at', label: 'Updated at' },
                        { value: 'completed_at', label: 'Completed at' },
                    ]}
                />
                <Radio.Group
                    optionType="button"
                    className={cl.compactRadioButtonGroup}
                    value={searchParams.get('sort_direction') || 'descend'}
                    onChange={({ target: { value } }) => patchSearchParams({ sort_direction: value })}
                    options={[
                        { value: 'descend', label: 'New to Old'},
                        { value: 'ascend', label: 'Old to New'},
                    ]}
                />
            </Space.Compact>
        </Row>
    );
};

export default ControlPanel;
