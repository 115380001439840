import { CheckOutlined, DeleteOutlined, FileAddOutlined, RetweetOutlined, WarningOutlined, ZoomInOutlined } from '@ant-design/icons';
import { Button, Rate } from 'antd';
import React, { useState } from 'react';
import { Fade } from 'transitions-kit';
import CustomLabel from './CustomLabel';
import ChoiseCustomLabel from './ChoiseCustomLabel';
import cl from "./ImageItem.module.css";


function ScoreIcon({ value }) {
    let color = 'green'

    switch (value) {
        case 1:
            color = 'red'
            break;
        case 2:
            color = 'orange'
            break;
        case 3:
            color = 'orange'
            break;
        case 4:
            color = 'green'
            break;
        case 5:
            color = '#fadb14'
            break;
        default:
            color = 'grey'
    }
    let style = { border: `3px solid ${color}`, color: color }

    return (
        <div className={cl.scoreBadge} style={{ ...style }} >
            <span className={cl.scoreIcon}>{value ? value : "!"}</span>
        </div>
    );
}

function ImageItem({ img, onChangeLabel, onChangeScore, onChangeGranular, onPreview, onLoaded, editMode = false }) {
    const [status, setStatus] = useState('loading')

    if (!img) {
        return
    }

    return (
        <>
            <Fade in={status === 'loaded'}>
                <div className={`${cl.imageItem} ${img.is_annotated ? cl.imageItemActive : ''}`} >
                    <div className={cl.imagePlaceholderContainer}>
                        <div className={cl.imagePlaceholder}></div>
                        <div className={cl.imagePlaceholderActions}>
                            <div className={cl.previewButton} onClick={() => { onPreview(img) }} type="dashed"><ZoomInOutlined /></div>

                            {img.label && <div className={cl.imageStatePlaceholder}>
                                {img.label == 'approved' && editMode == true && <CheckOutlined style={{ fontSize: '28px', color: '#fff' }} />}
                                {img.label == 'delete' && <DeleteOutlined style={{ fontSize: '28px', color: '#fff' }} />}
                                {img.label == 'injured' && <WarningOutlined style={{ fontSize: '28px', color: '#fff' }} />}
                                {img.label == 'relabel' && <RetweetOutlined style={{ fontSize: '28px', color: '#fff' }} />}
                                {img.label.startsWith('custom: ') && <FileAddOutlined style={{ fontSize: '28px', color: '#fff' }} />}
                            </div>}

                            <div className={cl.ratePlaceholder}>
                                <ScoreIcon value={img?.score} />
                                <Rate style={{ fontSize: '24px' }} onChange={(val) => { onChangeScore(img, parseInt(val)) }} defaultValue={0} character={({ index = 0 }) => index + 1} />
                            </div>

                            {editMode == true && <Button onClick={() => { onChangeLabel(img, 'approved') }} type="default"><CheckOutlined style={{ fontSize: '16px', color: 'green' }} /> Approve</Button>}
                            <Button onClick={() => { onChangeLabel(img, 'delete') }} type="default"><DeleteOutlined style={{ fontSize: '16px', color: 'red' }} /> Delete</Button>
                            <Button onClick={() => { onChangeLabel(img, 'relabel') }} type="default"><RetweetOutlined style={{ fontSize: '16px', color: 'blue' }} /> Relabel</Button>
                            <Button onClick={() => { onChangeLabel(img, 'injured') }} type="default"><WarningOutlined style={{ fontSize: '16px', color: 'orange' }} /> Injured</Button>

                            <CustomLabel onChange={(val) => { onChangeLabel(img, `custom: ${val}`) }} />

                            <ChoiseCustomLabel value={img?.granular} onChange={(val) => { onChangeGranular(img, val) }} />
                        </div>
                    </div>
                    <img
                        onLoad={() => { setStatus('loaded'); onLoaded(img) }}
                        onError={() => setStatus('failed')}
                        src={img?.url}
                    />
                </div>
            </Fade>
        </>
    )
}

export default ImageItem