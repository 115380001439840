import { BugOutlined, CloseCircleOutlined, RetweetOutlined, SlidersOutlined, StarOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import cl from "./images.module.css";


const AnnotationStatsBar = ({ annotation_stats }) => {
    if (!annotation_stats) {
        return null;
    }

    const get_label_props = (label) => {
        const label_props = {}

        if (label.label == 'delete') {
            label_props['color'] = 'red'
            label_props['icon'] = <CloseCircleOutlined />
        }

        if (label.label == 'injured') {
            label_props['color'] = 'green'
            label_props['icon'] = <BugOutlined />
        }

        if (label.label == 'relabel') {
            label_props['color'] = 'blue'
            label_props['icon'] = <RetweetOutlined />
        }

        return label_props
    }

    return (
        <div className={cl.annotationStatsBar}>
            {annotation_stats.labels.map((label, index) => {
                return <Tag key={`label_${index}`} {...get_label_props(label)}>{label.label}: ({label.count})</Tag>
            })}
            {annotation_stats.granulars.map((granular, index) => {
                return <Tag key={`granular_${index}`} icon={<SlidersOutlined />} color="purple">{granular.label}: ({granular.count})</Tag>
            })}
            {annotation_stats.scores.map((score, index) => {
                return <Tag key={`score_${index}`} icon={<StarOutlined />} color="green">scr: {score.label} ({score.count})</Tag>
            })}
        </div>
    )
}

export default AnnotationStatsBar;