import { Result } from 'antd';
import { useRouteError } from "react-router-dom";


export default function ErrorPage() {
    const error = useRouteError();
    console.error(error);

    return (
        <Result
            status={ error.status || 404}
            title={error.statusText || "404"}
            subTitle={error.message || "Sorry, the page you visited does not exist."}
        />
    );
}