import React from "react";
import ControlPanel from "./ControlPanel";
import UserRequestsList from "./UserRequestsList";

const AIExpertHelpPage = () => {
    return (
        <>
            <ControlPanel />
            <UserRequestsList />
        </>
    );
};

export default AIExpertHelpPage;
