import { Badge, Flex } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from "react-router-dom";
import ImagesService from "../../API/ImagesService";
import cl from "./FilterBar.module.css";


function Filter({ items, value, onChange }) {
    if (items.length == 0) {
        return null
    }

    return (
        <div className={cl.galleryFilter}>
            {
                items.map((item, index) => (
                    <div key={item.label} className={`${cl.galleryFilterItem} ${item.label == value ? cl.galleryFilterItemActive : ''}`} onClick={() => onChange(item.label)}>{item.label} <span className={cl.filterCntBadge}>{item.count}</span> {item.label == value && <Badge status="processing" />}</div>
                ))
            }
        </div>
    )
}

function FilterBar({ species }) {
    let [searchParams, setSearchParams] = useSearchParams();
    const patchSearchParams = (newParams = {}) => {
        setSearchParams((searchParams) => {
            const prevParams = {};
            searchParams.forEach((value, key) => {
                prevParams[key] = value;
            });
            // check if the some of new params are already in the searchParams
            for (const key in newParams) {
                if (prevParams[key] === newParams[key]) {
                    delete newParams[key];
                    delete prevParams[key];
                }
            }

            return { ...prevParams, ...newParams };
        });
    };

    const [items, setItems] = useState({
        'total_count': 0,
        'unlabeled_count': 0,
        'labels': [],
        'granulars': [],
        'scores': []
    })

    useEffect(() => {
        const fetchItems = async (species_id) => {
            const response = await ImagesService.getInfoAboutImageStats(species_id)
            setItems({ ...response.data })
        }
        if (species) {
            fetchItems(species)
        }
    }, [])

    const systemLabels = [
        { label: 'unlabeled', count: items['unlabeled_count'] },
        { label: 'all', count: items['total_count'] }
    ]

    return (
        <Flex gap={10} justify='center' align='center'>
            <Filter
                items={systemLabels}
                value={searchParams.get("label")}
                onChange={(val) => patchSearchParams({ 'label': val })}
            />
            <Filter
                items={items['labels']}
                value={searchParams.get("label")}
                onChange={(val) => patchSearchParams({ 'label': val })}
            />
            <Filter
                items={items['scores']}
                value={searchParams.get("score")}
                onChange={(val) => patchSearchParams({ 'score': val })}
            />
            <Filter
                items={items['granulars']}
                value={searchParams.get("granular")}
                onChange={(val) => patchSearchParams({ 'granular': val })}
            />
        </Flex>
    )
}

export default FilterBar