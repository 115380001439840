import React from 'react';
import { Button, Select, Drawer, Form, Input } from 'antd';
import { MailOutlined, UserOutlined, LockOutlined } from '@ant-design/icons';

const EditUserDrawer = ({
    open,
    initialValues,
    handleClose,
    handleSubmit,
    withRoleSelect,
}) => {
    return (
        <Drawer
            title="Edit user information"
            open={open}
            destroyOnClose
            onClose={handleClose}
        >
            <Form
                name='edit_user_form'
                layout='vertical'
                autoComplete='off'
                initialValues={initialValues}
                onFinish={handleSubmit}
            >
                <Form.Item
                    name="first_name"
                    label="First name"
                    rules={[
                        {
                            type: 'string',
                            required: true,
                            message: 'Please input first name!',
                        },
                    ]}
                >
                    <Input prefix={<UserOutlined className="site-form-item-icon" />} />
                </Form.Item>
                <Form.Item
                    name="last_name"
                    label="Last name"
                    rules={[
                        {
                            type: 'string',
                            required: true,
                            message: 'Please input last name!',
                        },
                    ]}
                >
                    <Input prefix={<UserOutlined className="site-form-item-icon" />} />
                </Form.Item>
                <Form.Item
                    name="email"
                    label="Email"
                    rules={[
                        {
                            type: 'email',
                            required: true,
                            message: 'Please input email!',
                        },
                    ]}
                >
                    <Input prefix={<MailOutlined className="site-form-item-icon" />} />
                </Form.Item>
                { withRoleSelect && (
                    <Form.Item
                        name="role"
                        label="Role"
                        rules={[
                            {
                                required: true,
                                message: 'PLease select role!'
                            }
                        ]}
                    >
                        <Select
                            options={[
                                { label: 'Viewer', value: 'viewer' },
                                { label: 'Annotator', value: 'annotator' },
                                { label: 'Admin', value: 'admin' },
                            ]}
                        />
                    </Form.Item>
                )}
                <Form.Item
                    name="password"
                    label="New password"
                    rules={[
                        {
                            pattern: /^\S*$/,
                            message: 'White space is not allowed',
                        },
                        {
                            min: 4,
                            message: 'Password must be at least 4 characters long',
                        },
                        {
                            max: 14,
                            message: 'Password can not exceed 14 characters',
                        }
                    ]}
                >
                    <Input prefix={<LockOutlined className="site-form-item-icon" />} placeholder="New password" />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Update
                    </Button>
                </Form.Item>
            </Form>
        </Drawer>
    );
};

export default EditUserDrawer;
