import { useState, useEffect, useMemo } from "react";
import { useMatch, useNavigate, useParams } from "react-router-dom";
import { useFetching } from "./useFetching";
import AnnotationService from "../API/AnnotationService";


const pageSize = 10;
const defaulfImgUrl = 'https://via.placeholder.com/224'

export const useAnnotateImages = () => {
    const { species } = useParams()
    const [percentOfAnnotated, setPercentOfAnnotated] = useState(0)
    const [images, setImages] = useState(Array(pageSize).map((v, k) => { return { url: defaulfImgUrl, label: '', image_id: k, _is_loading: true, score: 0 } }))
    const [limit, setLimit] = useState(pageSize)
    const [offset, setOffset] = useState(0)
    const [total, setTotal] = useState(0)
    const [progressStats, setProgressStats] = useState({ completed: 0, total: 0 })
    const [isLoading, setIsLoading] = useState(false);
    const [fetchError, setError] = useState('');

    const fetchImages = useFetching(async () => {
        const response = await AnnotationService.getImages(limit, offset, species);
        setTotal(response.data.count)
        setImages(response.data.results.map(image => ({ ...image, label: '', 'is_annotated': false })))
        setProgressStats({
            completed: response.data.stats.completed,
            total: response.data.stats.total
        })
        setPercentOfAnnotated(parseInt((response.data.stats.completed / response.data.stats.total) * 100))

    }, setIsLoading, setError)

    const loadMore = () => {
        setOffset(prevOffset => prevOffset + pageSize)
    }

    useEffect(() => {
        fetchImages()
    }, [offset]);

    const saveAnnotation = async () => {

        const prepareImage = (img) => {
            const data = {
                image_id: img.image_id,
                species: img.species,
                label: img.label,
                granular: img.granular
            }

            if (img.score > 0) {
                data.score = img.score
            }

            return data
        }
        const res = await AnnotationService.saveAnnotations(
            images.map(prepareImage)
        )
        // if (res.success) {
        //     setAnnotation(initialAnnotation)
        // }
    }

    const setImageAsLoaded = (imageId) => {
        const newImages = images.map(image => {
            if (image.image_id === imageId) {
                image._is_loading = false
                return image
            } else {
                return image
            }
        })
        setImages(newImages)
    }

    const changeImageLabel = (imageId, label) => {
        const newImages = images.map(image => {
            if (image.image_id === imageId) {
                image.is_annotated = true

                if (image.label === label) {
                    image.label = ''
                } else {
                    image.label = label
                }

                return image
            } else {
                return image
            }
        })
        setImages(newImages)
    }

    const changeImageScore = (imageId, score) => {
        const newImages = images.map(image => {
            if (image.image_id === imageId) {
                image.is_annotated = true
                image.score = score

                return image
            } else {
                return image
            }
        })

        setImages(newImages)
    }

    const changeImageGranular = (imageId, granular) => {
        const newImages = images.map(image => {
            if (image.image_id === imageId) {
                image.is_annotated = true

                if (image.granular === granular) {
                    image.granular = ''
                } else {
                    image.granular = granular
                }

                return image
            } else {
                return image
            }
        })

        setImages(newImages)
    }

    return [images, percentOfAnnotated, changeImageLabel, changeImageScore, changeImageGranular, isLoading, saveAnnotation, loadMore, setImageAsLoaded, progressStats]
}
