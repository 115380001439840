import React, { useState } from 'react';
import useSwr, { useSWRConfig } from 'swr';
import { zonedTimeToUtc } from 'date-fns-tz';
import { App as AntApp, Row, Col, Typography, Tag, Flex, Button, Result } from 'antd';
import { MailOutlined, EditOutlined } from '@ant-design/icons';
import UsersService from '../../../API/UsersService';
import { useAuth } from '../../../providers/AuthProvider';
import EditUserDrawer from '../../../components/EditUserDrawer/EditUserDrawer';

const { Title, Text, Paragraph } = Typography;

const largeTagStyles = {
    fontSize: 16,
    paddingInline: 12,
    paddingBlock: 2,
    marginInlineEnd: 0,
    height: 'fit-content',
};

async function fetcher() {
    const { data } = await UsersService.getCurrentUser();

    return data;
};

const RoleTag = ({ role, style }) => {
    switch (role) {
        case 'admin':
            return <Tag color='gold' style={style}>Admin</Tag>
        case 'annotator':
            return <Tag color='blue' style={style}>Annotator</Tag>
        case 'viewer':
            return <Tag color='cyan' style={style}>Viewer</Tag>
        default:
            return <Tag color='default' style={style}>Unknown</Tag>
    };
};

const ProfileInfo = ({ label, value }) => {
    return (
        <Flex justify='space-between'>
            <Paragraph strong>{label}</Paragraph>
            <Text>{value}</Text>
        </Flex>
    );
};

const ProfilePage = () => {
    const [showEditDrawer, setShowEditDrawer] = useState(false);
    const { setToken } = useAuth();
    const { message } = AntApp.useApp();
    const { mutate } = useSWRConfig();

    const { data, error } = useSwr('/api/users/me', fetcher, {
        revalidateOnReconnect: false,
        revalidateIfStale: false,
        revalidateOnFocus: false,
        keepPreviousData: true,
    });

    if (error) {
        return (
            <Result
                status='error'
                title='Error loading your profile page'
                subTitle='Please try again.'
            />
        );
    }

    if (!data) {
        return null;
    }

    const handleEditProfile = async (formValues) => {
        try {
            const { data } = await UsersService.updateUser(formValues, { setToken });

            if (!data.user) {
                throw new Error();
            }

            setTimeout(async () => {
                await mutate('/api/users/me');
            }, 0);

            message.success('Successfully updated your profile.');
        } catch (_e) {
            message.error('Error updating your profile.');
        }

        setShowEditDrawer(false);
    }

    return (
        <>
            <Row align='middle' justify='space-between' style={{ marginBottom: 36 }}>
                <Col>
                    <Flex align='center' gap={8} wrap='wrap'>
                        <Title style={{ marginBottom: 4 }}>{data.first_name} {data.last_name}</Title>
                        <RoleTag role={data.role} style={largeTagStyles} />
                        { data.is_superuser && <Tag color='green' style={largeTagStyles}>Super User</Tag> }
                    </Flex>
                    <Text>
                        <MailOutlined style={{ marginRight: 8 }} />
                        {data.email}
                    </Text>
                </Col>
                <Col>
                    <Button onClick={() => setShowEditDrawer(true)}>
                        <EditOutlined />
                        Edit profile
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col flex='auto' style={{ maxWidth: 400 }}>
                    <ProfileInfo
                        label='First name'
                        value={data.first_name}
                    />
                    <ProfileInfo
                        label='Last name'
                        value={data.last_name}
                    />
                    <ProfileInfo
                        label='Email'
                        value={data.email}
                    />
                    <ProfileInfo
                        label='Role'
                        value={<RoleTag role={data.role} style={{ marginInlineEnd: 0, height: 'fit-content' }} />}
                    />
                    <ProfileInfo
                        label='Last activity at'
                        value={data.last_activity_at ? zonedTimeToUtc(data.last_activity_at).toLocaleString() : '-'}
                    />
                    <ProfileInfo
                        label='Modified at'
                        value={data.modified_at ? zonedTimeToUtc(data.modified_at).toLocaleString() : '-'}
                    />
                    <ProfileInfo
                        label='Created at'
                        value={zonedTimeToUtc(data.created_at).toLocaleString()}
                    />
                </Col>
            </Row>
            <EditUserDrawer
                open={showEditDrawer}
                initialValues={data}
                handleSubmit={handleEditProfile}
                handleClose={() => setShowEditDrawer(false)}
            />
        </>
    );
};

export default ProfilePage;
