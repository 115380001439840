import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { App as AntApp, Button, Form, Input } from 'antd';
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import AuthService from '../API/api.auth';
import { useAuth } from '../providers/AuthProvider';
import '../styles/App.css';


function LoginPage() {
    const { message } = AntApp.useApp();
    const { setToken } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from || { pathname: '/plant-species' };

    const handleSubmit = async values => {
        try {
            const resp = await AuthService.login(values.email, values.password);
            setToken({
                access_token: resp.data.access_token,
                refresh_token: resp.data.refresh_token
            });
        } catch (err) {
            message.error("Login error");
            console.log("login error");
        }
        navigate(from.pathname, { replace: true });
    }

    return (
        <div className="login-wrapper">
            <Form
                name="normal_login"
                className="login-form"
                onFinish={handleSubmit}
            >
                <Form.Item
                    name="email"
                    rules={[
                        {
                            type: 'email',
                            required: true,
                            message: 'Please input your Email!',
                        },
                    ]}
                >
                    <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your password!',
                        },
                    ]}
                >
                    <Input
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        type="password"
                        placeholder="Password"
                    />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" className="login-form-button">
                        Log in
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}

export default LoginPage;