import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../providers/AuthProvider";
import { useLocation } from "react-router-dom";

const PrivateRoute = () => {
    const { token, user } = useAuth();
    const location = useLocation();

    if (token.access_token && token.access_token != 'null') {
        console.log("PrivateRoute: token found", atob(token.access_token.split('.')[1]));
        return <Outlet />
    }

    return <Navigate to="/login" state={{ from: location }} replace={true} />;
};

export default PrivateRoute;