import React, { useState } from 'react';
import Root from './root';
import ImageRetrieval from '../../components/ImageRetrieval/ImageRetrieval';


const ImageRetrievalPage = () => {

    return (
        <Root>
            <ImageRetrieval />
        </Root>
    );
}
export default ImageRetrievalPage