import { Modal } from 'antd';
import React, { useState } from 'react';
import AnnotationService from "../../API/AnnotationService";
import ImageItem from '../MemoImageViewer/ImageItem';


function EditIMageModal({ image, open, onCancel = () => { }, onSuccessSave = () => { } }) {
    const [img, setImg] = useState(() => {
        const data = { ...image }
        if (image.annotation) {
            if (image.annotation.label) {
                data.label = image.annotation.label
            }
            if (image.annotation.score) {
                data.score = image.annotation.score
            }
            if (image.annotation.granular) {
                data.granular = image.annotation.granular
            }
        }
        return data
    })
    const [isSaving, setIsSaving] = useState(false)

    const handleOnChangeImage = (action, img, value) => {
        switch (action) {
            case 'label':
                setImg({ ...img, 'label': value })
                break;
            case 'score':
                setImg({ ...img, 'score': value })
                break;
            case 'granular':
                setImg({ ...img, 'granular': value })
                break;
            default:
                console.log('no action')
                break;
        }
    }

    const handleOk = () => {
        setIsSaving(true)

        return new Promise((resolve) => {
            setTimeout(async () => {
                await saveAnnotation()
                onCancel()
                resolve(false);
            }, 0);
        });
    }

    const saveAnnotation = async () => {
        if (isSaving) return

        const res = await AnnotationService.saveAnnotations([img])

        if (res.status === 200) {
            onSuccessSave(img)
        } else {
            console.log('Error saving annotation', res)
        }
    }

    return (
        <>
            <Modal title="Edit annotation" okButtonProps={{ disabled: isSaving }} onOk={() => { handleOk().then((res) => { setIsSaving(res); }) }} open={open} onCancel={onCancel} >
                <ImageItem
                    onLoaded={(img) => { setImg({ ...img, _is_loading: false }) }}
                    onPreview={() => { }}
                    onChangeLabel={(img, label) => handleOnChangeImage('label', img, label)}
                    onChangeScore={(img, score) => handleOnChangeImage('score', img, score)}
                    onChangeGranular={(img, granular) => handleOnChangeImage('granular', img, granular)}
                    key={img.image_id}
                    img={img}
                    editMode={true}
                />
            </Modal >
        </>
    )
}

export default EditIMageModal;
